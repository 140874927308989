<div class="container">
  <header>
    <slot name="header"></slot>
  </header>
  <nav>
    <slot name="nav"></slot>
  </nav>
  <main>
    <slot name="main"></slot>
  </main>
</div>
