
import { defineComponent } from 'vue';
import Button from '../../atomic/atoms/Button/Button.vue';
import Link from '../../atomic/atoms/Link/Link.vue';
import Input from '../../atomic/atoms/Input/Input.vue';
import Checkbox from '../../atomic/atoms/Checkbox/Checkbox.vue';
import Card from '../../atomic/atoms/Card/Card.vue';
import Notification from '../../atomic/molecules/Notification/Notification.vue';
import AssetTemplate from '../../atomic/templates/AssetTemplate/AssetTemplate.vue';
import MultiSelectDropdown from '../../atomic/molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import MultiSelectInput from '../../atomic/molecules/Multi-SelectInput/MultiSelectInput.vue';
import DatePicker from '../../atomic/molecules/DatePicker/DatePicker.vue';
import { FormBuilder, FormControl } from '../../utilities/Forms';
import { Validators } from '../../utilities/Validators/Validators';
import { IDropdownOption } from '../../utilities/Interfaces/form.interfaces';

export default defineComponent({
  components: {
    'asset-template': AssetTemplate,
    'arc-button': Button,
    'arc-link': Link,
    'arc-input': Input,
    'arc-checkbox': Checkbox,
    'arc-card': Card,
    'arc-notification': Notification,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-multi-select-input': MultiSelectInput,
    'arc-date-picker': DatePicker,
  },

  data(): {
    pointlessNumber: number;
    selectedRoute: number;
    saving: boolean;
    disabled: boolean;
    formData: FormBuilder;
    dropdownFormData: FormBuilder;
    dateForm: FormBuilder;
    dropdownOptions: IDropdownOption[];
  } {
    return {
      pointlessNumber: 0,
      selectedRoute: 2,
      saving: true,
      disabled: true,
      formData: new FormBuilder({
        email: new FormControl('', [Validators.email, Validators.required]),
        password: new FormControl('', [Validators.password, Validators.required]),
      }),
      dropdownFormData: new FormBuilder({
        tags: new FormControl([], [Validators.required]),
        tags2: new FormControl([], [Validators.required]),
      }),
      dateForm: new FormBuilder({
        startDate: new FormControl('2021-11-15', [Validators.required]),
      }),
      dropdownOptions: [
        {
          description: 'Option 1',
          value: '1',
        },
        {
          description: 'Option 2',
          value: '2',
        },
        {
          description: 'Option 3',
          value: '3',
        },
        {
          description: 'Option 4',
          value: '4',
        },
      ],
      // selectedTags: [],
    };
  },

  mounted(): void {
    this.dateForm.controls.startDate.value = '2021-11-15';
  },

  methods: {
    selectRoute(routeNumber: number): void {
      this.selectedRoute = routeNumber;
    },
    toggleSaving(): void {
      this.saving = !this.saving;
    },
    submitForm(): void {
      this.formData.markAllAsTouched();

      if (this.formData.hasErrors) {
        return;
      }

      // Submit logic would go here.
    },
    submitTagForm(): void {
      this.dropdownFormData.markAllAsTouched();

      if (this.dropdownFormData.hasErrors) {
        return;
      }

      // Submit logic would go here.
    },
    submitDateForm(): void {
      this.dateForm.markAllAsTouched();

      if (this.dateForm.hasErrors) {
        return;
      }

      // Submit logic would go here.
    },
    validateControl(controlName: string): void {
      this.formData.controls[controlName].validate();
    },
    validateDropdownControl(controlName: string): void {
      setTimeout(() => {
        this.dropdownFormData.controls[controlName].validate();
      }, 0);
    },
    validateDateControl(controlName: string): void {
      setTimeout(() => {
        const date = new Date(`${this.dateForm.controls.startDate.value}`);
        this.dateForm.controls[controlName].validate();
      }, 0);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addNewOption(event: any): void {
      this.dropdownOptions.push({
        description: event,
        value: event,
      });
    },
  },
});
